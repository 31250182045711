import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../styles/media';
import footerBg from '../images/footer-bg-image.svg';
import footerBgMobile from '../images/footer-bg-image-mobile.svg';
import arrow from '../images/arrow.svg';

export default class CtaButtonModule extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
    buttonClick: PropTypes.func
  };
  render() {
    const { title, buttonText, buttonClick } = this.props;
    return (
      <CtaButtonStyles bg={footerBg} bgMobile={footerBgMobile}>
        <h2 className="cta-title">{title}</h2>
        <a href="#cta-form" className="cta-button" onClick={buttonClick}>
          {buttonText}
          <img
            src={arrow}
            className="button-icon"
            title="Arrow Button"
            alt="Arrow Icon in Button"
          />
        </a>
      </CtaButtonStyles>
    );
  }
}

// noinspection JSUnresolvedVariable
const CtaButtonStyles = styled.section`
  background: url(${props => props.bg}) no-repeat;
  background-size: cover;
  padding: 6rem 1.25rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA.TABLET`
    background: url(${props => props.bgMobile}) 50% 50% no-repeat;
    background-size: cover;
  `};
  
  .cta-title {
    margin: 0 0 3rem;
    line-height: 1.35;
    letter-spacing: -1.1px;
    font-size: 3.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.secondary};
    text-align: center;
    color: #ffffff;
  }

  .cta-button {
    display: flex;
    align-items: center;
    padding: 1rem 0.75rem 1rem 1.5rem;
    background-color: #fff;
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.primary};
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.28;

    .button-icon {
      width: 18px;
      margin-left: 2rem;
      transition: all 250ms ease-out;
    }

    &:hover {
      .button-icon {
        transform: translateX(-25%);
      }
    }
  }
`;
