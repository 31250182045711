import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../styles/media';
import LeadForm from './lead-form';
import nxtLogo from '../images/nxt-full-color.svg';

export default class CtaFormModule extends Component {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    titleLegal: PropTypes.string,
    formLegal: PropTypes.string,
    buttonText: PropTypes.string,
    formSubmission: PropTypes.func,
  };

  render() {
    const {
      title,
      subTitle,
      titleLegal,
      formLegal,
      buttonText,
      formSubmission,
    } = this.props;
    return (
      <CtaFormStyles id="cta-form">
        <div className="container">
          <div className="content-section">
            {/* Per request, overriding the title so the nxt logo can be included */}
            {/* <div className="title">{title}</div> */}
            <div className="title">
              Ready to see what{' '}
              <img className="logo" src={nxtLogo} alt="NXT Homes Logo" /> is all
              about?
            </div>
            <div className="sub-title">{subTitle}</div>
            <div className="title-legal">{titleLegal}</div>
          </div>
          <div className="form-section">
            <LeadForm
              options={{
                buttonText: buttonText,
                formLegal: formLegal,
              }}
              origins={{
                originWebApp: 'nxt-landing-page',
                originWebPageType: 'landing-page',
                originCampaign: 'nxt-2018',
                originWebFormCategory: '',
              }}
              onFormSubmit={formSubmission}
            />
          </div>
        </div>
      </CtaFormStyles>
    );
  }
}

const CtaFormStyles = styled.section`
  display: block;
  padding: 0 .5rem;

  .container {
    margin: 3.5rem auto;
    max-width: 1248px;
    display: flex;
    ${MEDIA.TABLET`
      flex-direction: column;
    `};

    .content-section {
      flex: 1;
      padding: 0 1rem;
      margin-bottom: 3rem;

      .title {
        margin-bottom: 1.5rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 2.25rem;
        font-weight: 400;
        line-height: 1.25;
        color: #6d6e71;
        ${MEDIA.TABLET`
          font-size: 2rem;
        `};

        .logo {
          height: 1.875rem;
          margin: 0 0.25rem;
        }
      }
      .sub-title {
        font-family: ${props => props.theme.fonts.primary};
        font-size: 1.5rem;
        line-height: 1.46;
        letter-spacing: -0.5px;
        color: #a2a2a3;
        max-width: 520px;
      }
      .title-legal {
        margin-top: 1.5rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 0.75rem;
        line-height: 1.42;
        letter-spacing: -0.3px;
        text-align: left;
        color: #6d6e71;
        max-width: 420px;
      }
    }

    .form-section {
      flex: 1;
      padding: 0 1rem;
    }
  }
`;
