import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Formik } from 'formik';
import { leadFormValidation } from '../util/validation';
import Spinner from './spinner';
import arrowRight from '../images/btn-arrow.svg';
import formService from '../util/form-service';
import { navigate } from '@reach/router';

export default class LeadForm extends Component {
  static propTypes = {
    options: PropTypes.shape({
      buttonText: PropTypes.string,
      formLegal: PropTypes.string,
      redirectUrl: PropTypes.string,
    }).isRequired,
    origins: PropTypes.shape({
      originWebApp: PropTypes.string.isRequired,
      originWebPageType: PropTypes.string.isRequired,
      originWebFormCategory: PropTypes.string,
      originCampaign: PropTypes.string,
    }).isRequired,
    onFormSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiError: '',
      active: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setActive = this.setActive.bind(this);
    this.setUnactive = this.setUnactive.bind(this);
  }

  setActive(e) {
    this.setState({
      active: {
        [e.target.name]: true,
      },
    });
  }

  setUnactive(e) {
    this.setState({
      active: {
        [e.target.name]: false,
      },
    });
  }

  handleSubmit(values, formikBag) {
    this.setState({ apiError: '' });
    typeof this.props.onFormSubmit === 'function' && this.props.onFormSubmit();
    formService
      .submitForm({
        ...values,
        ...this.props.origins,
        originWebFormType: 'lead-form',
      })
      .then(() => {
        navigate('/thank-you');
      })
      .catch(error => {
        console.error('ERROR SUBMITTING FORM', error);
        formikBag.setSubmitting(false);
        this.setState({
          apiError:
            'There was an error processing your request. Please try again or contact support.',
        });
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          emailAddress: '',
          firstName: '',
          lastName: '',
          zip: '',
          phoneNumber: '',
          canEmail: true,
        }}
        validationSchema={leadFormValidation}
        onSubmit={this.handleSubmit}
        render={({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => (
          <StyledForm onSubmit={handleSubmit} noValidate>
            {this.state.apiError && <ApiError>{this.state.apiError}</ApiError>}
            <StyledFieldset disabled={isSubmitting}>
              <Row>
                <Col>
                  <StyledFormGroup>
                    <StyledInput
                      name="firstName"
                      type="text"
                      valid={values.firstName && !errors.firstName}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={e => {
                        handleBlur(e);
                        this.setUnactive(e);
                      }}
                      onFocus={this.setActive}
                      error={touched.firstName && errors.firstName}
                      size={10}
                    />
                    <StyledLabel
                      float={values.firstName || this.state.active.firstName}
                      htmlFor="firstName"
                    >
                      First Name
                    </StyledLabel>
                    <StyledErrorMessage>
                      {touched.firstName && errors.firstName}
                    </StyledErrorMessage>
                  </StyledFormGroup>
                </Col>

                <Col>
                  <StyledFormGroup>
                    <StyledInput
                      name="lastName"
                      type="text"
                      valid={values.lastName && !errors.lastName}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={e => {
                        handleBlur(e);
                        this.setUnactive(e);
                      }}
                      onFocus={this.setActive}
                      error={touched.lastName && errors.lastName}
                      size={10}
                    />
                    <StyledLabel
                      float={values.lastName || this.state.active.lastName}
                      htmlFor="lastName"
                    >
                      Last Name
                    </StyledLabel>
                    <StyledErrorMessage>
                      {touched.lastName && errors.lastName}
                    </StyledErrorMessage>
                  </StyledFormGroup>
                </Col>
              </Row>

              <StyledFormGroup>
                <StyledInput
                  name="emailAddress"
                  type="email"
                  valid={values.emailAddress && !errors.emailAddress}
                  value={values.emailAddress}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    this.setUnactive(e);
                  }}
                  onFocus={this.setActive}
                  error={touched.emailAddress && errors.emailAddress}
                />
                <StyledLabel
                  float={values.emailAddress || this.state.active.emailAddress}
                  htmlFor="emailAddress"
                >
                  Email
                </StyledLabel>
                <StyledErrorMessage>
                  {touched.emailAddress && errors.emailAddress}
                </StyledErrorMessage>
              </StyledFormGroup>

              <StyledFormGroup>
                <StyledInput
                  name="zip"
                  type="text"
                  valid={values.zip && !errors.zip}
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    this.setUnactive(e);
                  }}
                  onFocus={this.setActive}
                  error={touched.zip && errors.zip}
                  size={10}
                />
                <StyledLabel
                  float={values.zip || this.state.active.zip}
                  htmlFor="zip"
                >
                  Zip
                </StyledLabel>
                <StyledErrorMessage>
                  {touched.zip && errors.zip}
                </StyledErrorMessage>
              </StyledFormGroup>

              <StyledFormGroup>
                <StyledInput
                  name="phoneNumber"
                  type="text"
                  valid={values.phoneNumber && !errors.phoneNumber}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e);
                    this.setUnactive(e);
                  }}
                  onFocus={this.setActive}
                  error={touched.phoneNumber && errors.phoneNumber}
                />
                <StyledLabel
                  float={values.phoneNumber || this.state.active.phoneNumber}
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </StyledLabel>
                <StyledErrorMessage>
                  {touched.phoneNumber && errors.phoneNumber}
                </StyledErrorMessage>
              </StyledFormGroup>

              <div className="form-legal">
                <input
                  type="checkbox"
                  name="canEmail"
                  checked={values.canEmail}
                  onBlur={e => {
                    handleBlur(e);
                    this.setUnactive(e);
                  }}
                  onChange={handleChange}
                />
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.options.formLegal,
                    }}
                  />
                  <StyledErrorMessage>{errors.canEmail}</StyledErrorMessage>
                </div>
              </div>

              <StyledButton
                type="submit"
                disabled={
                  Object.entries(touched).filter(t => t).length && !isValid
                }
              >
                {this.props.options.buttonText || 'Submit'}
                {!isSubmitting ? <img src={arrowRight} alt="" /> : <Spinner />}
              </StyledButton>
            </StyledFieldset>
          </StyledForm>
        )}
      />
    );
  }
}

const StyledForm = styled.form`
  .form-legal {
    position: relative;
    margin: 2rem 0 2.5rem;
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 0.75rem;
    line-height: 1.42;
    letter-spacing: -0.3px;
    color: #6d6e71;
    display: flex;
    input[type='checkbox'] {
      font-size: 2rem;
      flex: 0px 0px 1rem;
      margin: 0.25rem 0.5rem 0 0;
    }
    a {
      color: #6d6e71;
    }
  }
`;

const StyledFieldset = styled.fieldset`
  border: none;
  box-shadow: none;
  background: none;
  margin: 0;
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  margin: 0 -0.5em;
`;

const Col = styled.div`
  flex: 1;
  margin: 0 0.5rem;
`;

const StyledFormGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const StyledLabel = styled.label`
  display: block;
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  font-size: 1.375rem;
  line-height: 1;
  font-weight: 300;
  color: ${props => props.theme.colors.grayMedium};
  pointer-events: none;
  transition: 0.3s ease all;

  ${props =>
    props.float &&
    css`
      top: -0.75rem;
      left: 0.25rem;
      font-size: 0.875rem;
      font-weight: 600;
      color: ${props => props.theme.colors.secondary};
    `};
`;

const StyledInput = styled.input`
  margin: 0;
  padding: 0.375rem 0.25rem 0.25rem;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.375rem;
  line-height: 1.25;
  color: ${props => props.theme.colors.grayMedium};
  border: none;
  border-bottom: 1px solid
    ${props =>
      props.error ? props.theme.colors.danger : props.theme.borders.color};
  border-radius: ${props => props.theme.borders.radius};
  outline: none;
  background: #ffffff none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  transition: all 200ms ease-in;

  ${props =>
    props.valid &&
    css`
      border-color: ${props => props.theme.colors.secondary};
      border-width: 1.5px;
    `};

  &:focus {
    border-color: ${props => props.theme.colors.secondary};
    border-width: 1.5px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`;

const StyledErrorMessage = styled.div`
  position: absolute;
  left: 0.25rem;
  bottom: -1rem;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 0.75rem;
  line-height: 1;
  color: ${props => props.theme.colors.danger};
  font-weight: 600;
`;

const StyledButton = styled.button`
  margin: 1.5rem 0;
  border: none;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: ${props => props.theme.colors.secondary};
  color: #fff;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 200ms ease-in;
  opacity: 1;

  img {
    margin-left: 2rem;
    transition: all 200ms ease-in;
  }

  &:enabled:hover {
    background-color: ${props => props.theme.colors.primary};
    img {
      transform: translateX(-10%);
    }
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const ApiError = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  background-color: ${props => props.theme.colors.danger};
  color: white;
  font-size: 0.875rem;
  opacity: 0.9;
`;
